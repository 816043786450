<template>
  <div class="container-lg kap-container">
    <DashboardNavigation
      :shortMode="true"
      @filters-changed="handleFiltersChanged"
    ></DashboardNavigation>
    <div class="content" v-if="loadingPage">
      <h3>Loading....</h3>
    </div>
    <div class="content company-color" v-if="!loadingPage">
      <div>
        <div class="row mb-2">
          <div class="col-lg-8 col-9">
            <h3 class="pt-2 page-title text-break">
              {{ submission.business ? submission.business.name : '&nbsp;' }}
            </h3>
          </div>
          <div
            class="col-lg-4 col-3 text-right"
            v-if="isSubmissionEditable && !isSubmissionFromSalesforce"
          >
            <div role="group" class="btn-group">
              <router-link
                :to="'/submission/' + submission.uuid + '/edit'"
                :class="[
                  'mr-05',
                  'float-right',
                  'btn',
                  'kap-button',
                  'navigation-button',
                  'big-button',
                ]"
                id="edit-submission"
              >
                <span class="pc-only edit-text">Edit</span>
                <font-awesome-icon
                  :icon="['fas', 'edit']"
                  class="mobile-only edit-icon"
                />
              </router-link>
            </div>
          </div>
          <div
            class="col-lg-4 col-3 editable-message-wrapper"
            v-if="isSubmissionEditable && isSubmissionFromSalesforce"
          >
            <div role="group" class="btn-group">
              <span class="editable-warning"
                >This submission cannot be edited</span
              >
            </div>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-sm">
            <div class="small">Submission Status</div>
            <div>
              {{ statusMapping(submission.kapitus_application_status) }}
            </div>
          </div>
          <div
            class="col-sm"
            v-if="submission.kapitus_application_status === 'Funded'"
          >
            <div class="small">
              Commission Accrued<sup
                :class="['asterisk-icon']"
                data-toggle="modal"
                data-target="#commissionModal"
              >
                <font-awesome-icon :icon="['fas', 'asterisk']" />
              </sup>
            </div>
            <div>{{ formatCurrency(submission.commission_amount) }}</div>
          </div>
        </div>
        <div
          class="row pt-2"
          v-if="submission.kapitus_application_status === 'Funded'"
        >
          <div class="col-sm">
            <div class="small">Funded Amount</div>
            <div>{{ formatCurrency(submission.funded_amount) || '-' }}</div>
          </div>
          <div class="col-sm">
            <div class="small">Funded Date</div>
            <div>{{ formatDate(submission.funded_date) || '-' }}</div>
          </div>
        </div>
        <div
          class="row pt-2"
          v-if="submission.kapitus_application_status === 'Funded'"
        >
          <div class="col-sm">
            <div class="small">Financing Provider</div>
            <div>{{ submission.financing_provider || '-' }}</div>
          </div>
          <div class="col-sm">
            <div class="small">Sale Type</div>
            <div>{{ submission.sale_type || '-' }}</div>
          </div>
        </div>
        <br />
        <h3 class="page-title">Application info</h3>
        <hr />

        <div class="row">
          <div class="col-sm">
            <div class="small">Requested Funding</div>
            <div>{{ formatCurrency(submission.requested_funding) || '-' }}</div>
          </div>
          <div class="col-sm">
            <div class="small">Use of Funds</div>
            <div>
              {{ submission.use_of_funds ? submission.use_of_funds : '-' }}
            </div>
          </div>
        </div>
      </div>

      <div class="pt-4">
        <h3 class="page-title">Business info</h3>
        <hr />
        <div class="row">
          <div class="col-sm">
            <div class="small">Legal Name</div>
            <div>
              {{ (submission.business ? submission.business.name : '') || '-' }}
            </div>
          </div>
          <div class="col-sm">
            <div class="small">DBA</div>
            <div>
              {{ (submission.business ? submission.business.dba : '') || '-' }}
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-sm">
            <div class="small">Entity Type</div>
            <div>
              {{
                (submission.business ? submission.business.entity_type : '') ||
                '-'
              }}
            </div>
          </div>
          <div class="col-sm">
            <div class="small">Business Phone</div>
            <div>
              {{
                (submission.business ? submission.business.phone : '') || '-'
              }}
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-sm">
            <div class="small">Industry</div>
            <div>
              {{
                (submission.business ? submission.business.industry : '') || '-'
              }}
            </div>
          </div>
          <div class="col-sm">
            <div class="small">Business Start Date</div>
            <div>
              {{
                (submission.business
                  ? formatMonthYear(submission.business.start_date)
                  : '') || '-'
              }}
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-sm">
            <div class="small">Business Address</div>
            <div>
              {{
                (submission.business ? submission.business.address : '') || '-'
              }}
            </div>
          </div>
          <div class="col-sm">
            <div class="small">Business Email</div>
            <div>
              {{
                (submission.business
                  ? getFirstEmail(submission.business)
                  : '') || '-'
              }}
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-sm">
            <div class="small">Tax Id</div>
            <div>
              {{
                submission.business && submission.business.tin_provided
                  ? '**-*******'
                  : '-'
              }}
            </div>
          </div>
          <div class="col-sm">
            <div class="small">Gross Annual Revenue</div>
            <div>
              {{
                (submission.business
                  ? formatCurrency(submission.business.gross_annual_revenue)
                  : '') || '-'
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="pt-4" v-if="submission.owners.length > 0">
        <h3 class="page-title">Owners</h3>
        <hr />
        <div
          v-for="(owner, index) in submission.owners"
          v-bind:key="'owner-' + index"
        >
          <h4>{{ owner.first_name + ' ' + owner.last_name }}</h4>
          <div class="row pt-2">
            <div class="col-sm">
              <div class="small">Email</div>
              <div>{{ getFirstEmail(owner) || '-' }}</div>
            </div>
            <div class="col-sm">
              <div class="small">Phone</div>
              <div>{{ getFirstPhone(owner) || '-' }}</div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-sm">
              <div class="small">Birth Date</div>
              <div>{{ formatDate(owner.birth_date) || '-' }}</div>
            </div>
            <div class="col-sm">
              <div class="small">Address</div>
              <div>{{ getFirstAddress(owner) || '-' }}</div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-sm">
              <div class="small">Ownership</div>
              <div>
                {{
                  owner.percentage_ownership
                    ? owner.percentage_ownership + '%'
                    : '-'
                }}
              </div>
            </div>
            <div class="col-sm">
              <div class="small">FICO</div>
              <div>{{ owner.fico || '-' }}</div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-sm">
              <div class="small">SSN</div>
              <div>{{ owner.ssn_provided ? '***-**-****' : '-' }}</div>
            </div>
            <div class="col-sm"></div>
          </div>
          <br />
        </div>
      </div>

      <h3>Documents</h3>
      <DataTable
        :lazy="true"
        :value="submission.documents"
        key="document_list"
        :class="['p-datatable-striped p-datatable-sm kap-datatable']"
        ref="dt"
        :paginator="false"
        :first="0"
        :rows="50"
        dataKey="uuid"
        :rowHover="submission.documents.length > 0"
        :loading="loadingPage"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        responsiveLayout="stack"
        :alwaysShowPaginator="false"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #empty> No documents were attached. </template>
        <template #loading> Loading document data. Please wait... </template>
        <Column
          v-for="col of columns"
          :field="col.field"
          :header="col.header"
          :key="col.field"
          bodyClass="cursor-default"
        >
          <template #body="document">
            {{ document.data[col.field] }}
          </template>
        </Column>
      </DataTable>
    </div>
    <CommissionModal ref="commissionModal"></CommissionModal>
  </div>
</template>

<script>
import { Dictionary } from '@/constants/dictionary';
import { ApplicationModel } from '@/models/ApplicationModel';
import { findWhere, isEmpty, filter, find } from 'underscore';
import DashboardNavigation from '../DashboardNavigation';
import apiService from '../../plugins/api';
import utils from '../../utils/';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import CommissionModal from '../Modal/CommissionModal';

export default {
  name: 'ViewSubmission',
  props: ['handleFiltersChanged'],
  data() {
    return {
      loadingPage: true,
      submission: {},
      submissionDocuments: [],
      dictionary: Dictionary,
      application_model: ApplicationModel,
      columns: [
        { field: 'name', header: 'Name' },
        { field: 'type', header: 'Type' },
        { field: 'created_at', header: 'Uploaded' },
      ],
    };
  },
  methods: {
    async fetchSubmission() {
      try {
        const response = await apiService().getSubmission(
          this.$route.params.submission_id
        );
        if (typeof response.data !== 'undefined') {
          this.submission = await this.formatData({
            ...this.application_model,
            ...response.data,
          });
          document.title = `View "${
            this.submission.business ? this.submission.business.name : ''
          }" application`;
        }
        this.loadingPage = false;
      } catch (e) {
        console.error(e);
        this.$router.push({ path: '/404' });
      }
    },

    formatData(data) {
      if (data.business && data.business.entity_type) {
        data.business.entity_type = findWhere(
          this.dictionary.business_entity_type,
          { value: utils.mapOldSubmissionEntityType(data.business.entity_type) }
        ).text;
      }

      if (data.business && !isEmpty(data.business.locations)) {
        data.business.address = this.getFirstAddress(
          data.business.locations[0]
        );
        data.business.phone = this.getFirstPhone(data.business.locations[0]);
      }

      this.formatDocumentsFromSubmission(data);

      return data;
    },

    formatDocumentsFromSubmission(submission) {
      for (const i in submission.documents) {
        const document = submission.documents[i];
        document.type = Dictionary.document_types[document.type];
        document.created_at = this.utcToLocalDateTime(document.created_at);
      }
    },

    formatDate(value) {
      return utils.formatShortDate(value);
    },

    formatMonthYear(value) {
      return utils.formatMonthYear(value);
    },

    formatCurrency(value) {
      return utils.formatCurrency(value);
    },

    formatDatetime(value) {
      if (!value) {
        return null;
      }
      const d = new Date(value);
      return `${
        d.getMonth() + 1
      }/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    },

    getFirstAddress(entity) {
      if (!isEmpty(entity.addresses)) {
        return this.getFullAddress(entity.addresses[0]);
      }
      return '';
    },

    getFullAddress(obj) {
      const state = find(
        Dictionary.states,
        (stateItem) => stateItem.value === obj.state
      );
      return filter(
        [
          obj.address_1,
          obj.address_2,
          obj.city,
          state && state.text ? state.text : '',
          obj.postal_code,
        ],
        (val) => val
      ).join(', ');
    },

    getFirstEmail(entity) {
      if (!isEmpty(entity.emails)) {
        return entity.emails[0].email;
      }
      return undefined;
    },

    getFirstPhone(entity) {
      if (!isEmpty(entity.phonenumbers)) {
        return entity.phonenumbers[0].number;
      }
      return undefined;
    },

    utcToLocalDateTime(value) {
      return utils.utcToLocalDateTime(value);
    },

    statusMapping(status) {
      return Dictionary.statuses_correspondence[status.toLowerCase()] ?? '';
    },
  },
  mounted() {
    this.fetchSubmission();
  },
  components: {
    DashboardNavigation,
    Column,
    DataTable,
    CommissionModal,
  },
  computed: {
    isSubmissionEditable() {
      return utils.isSubmissionEditable(this.submission);
    },

    isSubmissionFromSalesforce() {
      return utils.isSubmissionFromSalesforce(this.submission);
    },
  },
};
</script>

<style scoped></style>
