import moment from 'moment';
import { Dictionary } from '@/constants/dictionary';
import RegularExpressions from '@/constants/regular-expressions';

export default {
  validatePassword(passwordRules, password, passwordRepeated) {
    passwordRules.minLength = this.checkPasswordRuleMinLength(password);
    passwordRules.spaces = !this.checkPasswordRule(
      new RegExp(RegularExpressions.RULE_SPACES),
      password
    );
    passwordRules.hasNumber = this.checkPasswordRule(
      new RegExp(RegularExpressions.RULE_HAS_NUMBER),
      password
    );
    passwordRules.specialChar = !this.checkPasswordRule(
      new RegExp(RegularExpressions.RULE_SPECIAL_CHAR),
      password
    );
    passwordRules.uppercase = this.checkPasswordRule(
      new RegExp(RegularExpressions.RULE_UPPERCASE),
      password
    );
    passwordRules.lowercase = this.checkPasswordRule(
      new RegExp(RegularExpressions.RULE_LOWERCASE),
      password
    );
    passwordRules.passwordMatch = password == passwordRepeated;
    return passwordRules;
  },

  flattenApiValidationErrors(data) {
    const result = {};
    const hasNestedObjectOrArray = (currentObject) => {
      let hasNestedObject = false;
      for (const p in currentObject) {
        if (
          (typeof currentObject[p] === 'object' && currentObject[p] !== null) ||
          Array.isArray(currentObject[p])
        ) {
          hasNestedObject = true;
          break;
        }
      }
      return hasNestedObject;
    };

    function recurse(currentObject, propertyName) {
      if (Array.isArray(currentObject)) {
        for (let i = 0, l = currentObject.length; i < l; i++) {
          recurse(currentObject[i], `${propertyName}${i}`);
        }
      } else {
        if (hasNestedObjectOrArray(currentObject)) {
          for (const p in currentObject) {
            recurse(
              currentObject[p],
              propertyName ? `${propertyName}_${p}` : p
            );
          }
        } else {
          result[propertyName] = Object.values(currentObject)['0'];
        }
      }
    }

    recurse(data, '');
    return result;
  },

  sanitize(obj) {
    const result = JSON.parse(JSON.stringify(obj));
    const tokensToRemove = ['[]', '{}', '[{}]', '[{"type":"work"}]'];

    const rec = (o) => {
      for (const prop in o) {
        if (Array.isArray(o[prop])) {
          for (const idx in o[prop]) {
            rec(o[prop][idx]);
          }
        } else if (typeof o[prop] === 'object' && o[prop] !== null) {
          rec(o[prop]);
        }

        if (
          !o[prop] ||
          (o[prop] && tokensToRemove.includes(JSON.stringify(o[prop])))
        ) {
          delete o[prop];
        }
      }
    };
    rec(result);
    return result;
  },

  formatShortDate(value) {
    if (!value) {
      return null;
    }
    return this.formatDate(value + 'T08:00:00.000Z');
  },

  formatMonthYear(value) {
    if (!value) {
      return null;
    }
    return this.formatMonthYearDate(value + 'T08:00:00.000Z');
  },

  formatDate(value) {
    if (!value) {
      return null;
    }

    return new Date(value).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  },

  formatMonthYearDate(value) {
    if (!value) {
      return null;
    }

    return new Date(value).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
    });
  },

  formatCurrency(value) {
    const providedValue = !value ? 0 : 0 + value;
    return providedValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },

  isSubmissionEditable(submission) {
    const status = submission.kapitus_application_status || '';
    return (
      status.length === 0 ||
      Dictionary.statuses_correspondence[status.toLowerCase()] === 'Incomplete'
    );
  },

  isSubmissionFromSalesforce(submission) {
    const submission_origin = submission?.submission_origin || '';
    return submission_origin === 'Kapitus';
  },

  utcToLocalDate(value) {
    return moment.utc(value).local().format('MM/DD/YYYY');
  },

  utcToLocalDateTime(value) {
    return moment.utc(value).local().format('MM/DD/YYYY HH:mm');
  },

  checkPasswordRuleMinLength(val) {
    return val.length >= 8;
  },

  checkPasswordRule(re, val) {
    return re.test(val);
  },

  checkRulesSatisfied(passwordRules) {
    const rules = Object.values(passwordRules);
    return rules.every((v) => v === rules[0]);
  },

  getFiltersFromUrl(routeQuery) {
    let filterStr = '';
    const dateStart = routeQuery?.dateStart;
    const dateEnd = routeQuery?.dateEnd;
    const allTime = routeQuery?.allTime;
    if (dateStart && dateEnd) {
      filterStr += `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      filterStr += `&allTime=${allTime ?? 'false'}`;
    }
    return filterStr;
  },

  mapOldSubmissionEntityType(oldEntityType) {
    const entityTypestMap = {
      llc: 'Limited Liability Company (LLC)',
      inc: 'S Corporation (S Corp)',
      corp: 'C Corporation (C Corp)',
      partnership: 'General Partnership (GP)',
      sole: 'Sole Proprietorship',
    };

    return entityTypestMap[oldEntityType]
      ? entityTypestMap[oldEntityType]
      : oldEntityType;
  },
};
