export default (axios, config, router) => ({
  getPartner(partnerId) {
    return this.simpleRequestWrapper(
      axios.get(`${config.Api.url}api/v2/partners/${partnerId}`)
    );
  },

  getPartnerChildren(partnerId) {
    return this.simpleRequestWrapper(
      axios.get(`${config.Api.url}api/v2/partners/${partnerId}/children`)
    );
  },

  createPartialSubmission(data) {
    return this.simpleRequestWrapper(
      axios.post(`${config.Api.url}api/v2/referrals/`, data)
    );
  },

  simpleRequestWrapper(req) {
    return req
      .then((response) => {
        return response.data;
      })
      .catch(this.errorHandler.bind(this));
  },

  errorHandler(error) {
    this.checkErrorResponse(error);
    return Promise.reject(error.response ? error.response.data : {});
  },

  updatePartialSubmission(submissionId, data) {
    return this.simpleRequestWrapper(
      axios.put(`${config.Api.url}api/v2/referrals/${submissionId}`, data)
    );
  },

  deleteDocument(submissionId, documentId) {
    return this.simpleRequestWrapper(
      axios.delete(
        `${config.Api.url}api/v2/applications/${submissionId}/documents/${documentId}`
      )
    );
  },

  uploadDocument(submissionId, formData, progressCallback) {
    return this.simpleRequestWrapper(
      axios.post(
        `${config.Api.url}api/v2/applications/${submissionId}/documents`,
        formData,
        { onUploadProgress: progressCallback }
      )
    );
  },

  getSubmission(submissionId) {
    return this.simpleRequestWrapper(
      axios.get(`${config.Api.url}api/v2/applications/${submissionId}`)
    );
  },

  getPartnerUsers(partnerId) {
    return this.simpleRequestWrapper(
      axios.get(`${config.Api.url}api/users/partner/${partnerId}`)
    );
  },

  updatePartner(partnerObject) {
    return axios
      .put(
        `${config.Api.url}api/v2/partners/${partnerObject.uuid}`,
        partnerObject
      )
      .then((response) => {
        if (response.data.status === "success") {
          return response.data;
        }
        return {};
      })
      .catch(this.errorHandler.bind(this));
  },

  getListOfSubmissions(params) {
    let paramsString;
    if (Object.keys(params).length > 0) {
      const searchParams = new URLSearchParams();
      for (const key in params) {
        searchParams.append(key, params[key]);
      }
      paramsString = searchParams.toString();
    }

    return this.simpleRequestWrapper(
      axios.get(
        `${config.Api.url}api/v2/applications${
          paramsString ? "?" + paramsString : ""
        }`
      )
    );
  },

  checkErrorResponse(error) {
    if (error.response) {
      if (error.response.status === 403) {
        router.push("/logout");
      } else if (error.response.status === 404) {
        router.push("/404");
      }
    }
  },

  getSubmissionsByCategory(params) {
    const searchParams = new URLSearchParams();
    if (
      typeof params.toDate !== "undefined" &&
      params.toDate !== "" &&
      params.toDate != null
    ) {
      searchParams.append(
        "range[end]",
        this.formatDateToISOString(params.toDate)
      );
    }
    if (
      typeof params.fromDate !== "undefined" &&
      params.fromDate !== "" &&
      params.fromDate != null
    ) {
      searchParams.append(
        "range[start]",
        this.formatDateToISOString(params.fromDate)
      );
    }
    return this.simpleRequestWrapper(
      axios.get(
        `${
          config.Api.url
        }api/v2/portal/submissions/breakdown?${searchParams.toString()}`
      )
    );
  },

  getSubmissions(params) {
    const searchParams = new URLSearchParams();
    searchParams.append("direction", params.direction);
    searchParams.append("sort", params.order_by);

    if (typeof params.kapitus_application_status !== "undefined") {
      searchParams.append(
        "kapitus_application_status",
        params.kapitus_application_status
      );
    }
    if (params.pagination !== null) {
      if (typeof params.pagination.page !== "undefined") {
        searchParams.append("page", params.pagination.page);
      }
      if (typeof params.pagination.limit !== "undefined") {
        searchParams.append("limit", params.pagination.limit);
      }
      if (
        typeof params.fromDate !== "undefined" &&
        params.fromDate !== "" &&
        params.fromDate != null
      ) {
        searchParams.append(
          "range[start]",
          this.formatDateToISOString(params.fromDate)
        );
      }
      if (
        typeof params.toDate !== "undefined" &&
        params.toDate !== "" &&
        params.toDate != null
      ) {
        searchParams.append(
          "range[end]",
          this.formatDateToISOString(params.toDate)
        );
      }
    }

    if (params.search_term) {
      searchParams.append(
        "filter[Businesses.name]",
        "%" + (params.search_term + "").replaceAll("%", "")
      );
    }

    return this.simpleRequestWrapper(
      axios.get(
        `${config.Api.url}api/v2/applications?${searchParams.toString()}`
      )
    );
  },

  formatDateToApiDateShort(value) {
    if (!value) {
      return null;
    }
    return new Date(value).toISOString().split("T")[0];
  },

  formatDateToISOString(value) {
    if (!value) {
      return null;
    }
    return value.toISOString();
  },
});
