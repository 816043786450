export const Dictionary = Object.freeze({
  business_entity_type: [
    { text: '', value: '' },
    {
      text: 'Limited Liability Company (LLC)',
      value: 'Limited Liability Company (LLC)',
    },
    { text: 'S Corporation (S Corp)', value: 'S Corporation (S Corp)' },
    { text: 'C Corporation (C Corp)', value: 'C Corporation (C Corp)' },
    {
      text: 'Limited Liability Partnership (LLP)',
      value: 'Limited Liability Partnership (LLP)',
    },
    { text: 'Sole Proprietorship', value: 'Sole Proprietorship' },
    {
      text: 'Professional Limited Liability Company (PLLC)',
      value: 'Professional Limited Liability Company (PLLC)',
    },
    {
      text: 'Professional Corporation (PC)',
      value: 'Professional Corporation (PC)',
    },
    { text: 'Limited Partnership (LP)', value: 'Limited Partnership (LP)' },
    {
      text: 'Limited Liability Limited Partnership (LLLP)',
      value: 'Limited Liability Limited Partnership (LLLP)',
    },
    { text: 'General Partnership (GP)', value: 'General Partnership (GP)' },
  ],
  use_of_funds: [
    { text: '', value: '' },
    { text: 'Payroll', value: 'Payroll' },
    { text: 'Equipment', value: 'Equipment' },
    { text: 'Renovations', value: 'Renovations' },
    { text: 'Expansion', value: 'Expansion' },
    { text: 'Repairs', value: 'Repairs' },
    { text: 'Marketing', value: 'Marketing' },
    { text: 'Taxes', value: 'Taxes' },
    { text: 'Other', value: 'Other' },
  ],
  business_industry: [
    { text: '', value: '' },
    { text: 'Medical', value: 'Medical' },
    { text: 'Restaurants', value: 'Restaurants' },
    { text: 'Manufacturing', value: 'Manufacturing' },
    { text: 'Wholesaler', value: 'Wholesaler' },
    { text: 'Retailer', value: 'Retailer' },
    { text: 'Other', value: 'Other' },
  ],
  states: [
    { text: '', value: '' },
    { text: 'Alabama', value: 'AL' },
    { text: 'Alaska', value: 'AK' },
    { text: 'Arizona', value: 'AZ' },
    { text: 'Arkansas', value: 'AR' },
    { text: 'California', value: 'CA' },
    { text: 'Colorado', value: 'CO' },
    { text: 'Connecticut', value: 'CT' },
    { text: 'Delaware', value: 'DE' },
    { text: 'District Of Columbia', value: 'DC' },
    { text: 'Florida', value: 'FL' },
    { text: 'Georgia', value: 'GA' },
    { text: 'Hawaii', value: 'HI' },
    { text: 'Idaho', value: 'ID' },
    { text: 'Illinois', value: 'IL' },
    { text: 'Indiana', value: 'IN' },
    { text: 'Iowa', value: 'IA' },
    { text: 'Kansas', value: 'KS' },
    { text: 'Kentucky', value: 'KY' },
    { text: 'Louisiana', value: 'LA' },
    { text: 'Maine', value: 'ME' },
    { text: 'Maryland', value: 'MD' },
    { text: 'Massachusetts', value: 'MA' },
    { text: 'Michigan', value: 'MI' },
    { text: 'Minnesota', value: 'MN' },
    { text: 'Mississippi', value: 'MS' },
    { text: 'Missouri', value: 'MO' },
    { text: 'Montana', value: 'MT' },
    { text: 'Nebraska', value: 'NE' },
    { text: 'Nevada', value: 'NV' },
    { text: 'New Hampshire', value: 'NH' },
    { text: 'New Jersey', value: 'NJ' },
    { text: 'New Mexico', value: 'NM' },
    { text: 'New York', value: 'NY' },
    { text: 'North Carolina', value: 'NC' },
    { text: 'North Dakota', value: 'ND' },
    { text: 'Ohio', value: 'OH' },
    { text: 'Oklahoma', value: 'OK' },
    { text: 'Oregon', value: 'OR' },
    { text: 'Pennsylvania', value: 'PA' },
    { text: 'Rhode Island', value: 'RI' },
    { text: 'South Carolina', value: 'SC' },
    { text: 'South Dakota', value: 'SD' },
    { text: 'Tennessee', value: 'TN' },
    { text: 'Texas', value: 'TX' },
    { text: 'Utah', value: 'UT' },
    { text: 'Vermont', value: 'VT' },
    { text: 'Virginia', value: 'VA' },
    { text: 'Washington', value: 'WA' },
    { text: 'West Virginia', value: 'WV' },
    { text: 'Wisconsin', value: 'WI' },
    { text: 'Wyoming', value: 'WY' },
  ],
  urgency: [
    { text: '', value: '' },
    { text: '0-3', value: '0-3' },
    { text: '3-6', value: '3-6' },
    { text: '6-9', value: '6-9' },
    { text: '9-12', value: '9-12' },
    { text: '12+', value: '12+' },
  ],
  document_types: {
    dl: "Driver's License",
    bl: 'Business License',
    sa: 'Standard Application',
    bs: 'Bank Statement',
    cc: 'Credit Card Statement',
    cl: 'Commercial Lease',
    pa: 'Purchase Agreement',
    lv: 'Landlord Verification',
    ms: 'Mortgage Statement',
    pu: 'Personal Utility Bill',
    bu: 'Business Utility Bill',
    tr: 'Tax Return',
    fs: 'Financial Statement',
    br: 'Bank Reference',
    oo: 'Other',
    sig: 'Signature',
    vc: 'Voided Check',
    sct: 'Signed Contract',
    uct: 'Unsigned Contract',
    cbo: 'Contract Balance Buy Out Form',
    paf: 'Payment Authorization Form',
  },
  submission_statuses: [
    {
      name: 'All Submissions',
      href: '/submissions?status=All',
      title: 'All Submissions',
    },
    {
      name: 'Incomplete',
      href: '/submissions?status=Incomplete',
      title: 'Incomplete Submissions',
    },
    {
      name: 'Credit Review',
      href: '/submissions?status=Credit Review',
      title: 'Submissions in Credit Review',
    },
    {
      name: 'Approved',
      href: '/submissions?status=Approved',
      title: 'Approved Submissions',
    },
    {
      name: 'Declined',
      href: '/submissions?status=Declined',
      title: 'Declined Submissions',
    },
    {
      name: 'Expired',
      href: '/submissions?status=Expired',
      title: 'Expired Submissions',
    },
    {
      name: 'Funded',
      href: '/submissions?status=Funded',
      title: 'Funded Submissions',
    },
  ],
  statuses_correspondence: {
    'not delivered': 'Incomplete',
    incomplete: 'Incomplete',
    'update requested': 'Incomplete',
    'application received': 'Credit Review',
    'credit review': 'Credit Review',
    approved: 'Approved',
    closing: 'Approved',
    'closing documents missing': 'Approved',
    'contract sent': 'Approved',
    'contract received': 'Approved',
    declined: 'Declined',
    expired: 'Expired',
    funded: 'Funded',
    'closed/lost': 'Expired',
  },
});
