<template>
  <div class="container-lg kap-container company-color">
    <DashboardNavigation
      :shortMode="true"
      @filters-changed="handleFiltersChanged"
    ></DashboardNavigation>
    <div class="content" v-if="isLoading">
      <div class="spinner-border text-primary">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="content company-color" v-if="!isLoading">
      <h3>{{ submission.business.name }}</h3>
      <hr />
      <Form
        @submit="updateSubmission"
        v-slot="{ errors }"
        :validation-schema="schema"
        @input="onFormChange"
      >
        <div>
          <div class="row mt-3 col-12">
            <strong>Expedite Submission.&nbsp;</strong>
            If you have the following information, your referral application
            will be expedited and processed immediately.
          </div>

          <div>
            <!-- Funding Information -->
            <h4 class="mt-4">Funding Information</h4>
            <hr />
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="requested_funding"
                  label="Requested Funding Amount"
                  type="number"
                  :validation-error="apiErrors['requested_funding']"
                  v-model="submission.requested_funding"
                />
              </div>
              <div class="col-12 col-md-6">
                <SelectField
                  id="use_of_funds"
                  label="Use of Funds"
                  :validation-error="apiErrors['use_of_funds']"
                  :options="select.use_of_funds"
                  v-model="submission.use_of_funds"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <SelectField
                  id="urgency"
                  label="How soon does the merchant need funds (months)?"
                  :validation-error="apiErrors['urgency']"
                  :options="select.urgency"
                  v-model="submission.urgency"
                />
              </div>
            </div>
          </div>
          <!-- Business Information -->
          <div>
            <h4 class="mt-4">Business Information</h4>
            <hr />
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="business_name"
                  label="Business Name"
                  :validation-error="apiErrors['business_name']"
                  v-model="submission.business.name"
                />
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="business_dba"
                  label="DBA"
                  :validation-error="apiErrors['business_dba']"
                  v-model="submission.business.dba"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <SelectField
                  id="business_entity_type"
                  label="Entity Type"
                  :validation-error="apiErrors['business_entity_type']"
                  :options="select.business_entity_type"
                  v-model="submission.business.entity_type"
                />
              </div>
              <div class="col-12 col-md-6">
                <SelectField
                  id="business_industry"
                  label="Industry Type"
                  :validation-error="apiErrors['business_industry']"
                  :options="select.business_industry"
                  v-model="submission.business.industry"
                />
              </div>
            </div>
            <div class="row" v-if="showMapSearch">
              <div class="col-12">
                <div class="form-group">
                  <label for="business_address">Business Address Search</label>
                  <VueGoogleAutocomplete
                    id="business_address"
                    country="us"
                    classname="form-control company-color"
                    placeholder="Start typing address"
                    @placechanged="
                      setAddress(
                        submission.business.locations[0].addresses[0],
                        $event
                      )
                    "
                  >
                  </VueGoogleAutocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <InputField
                  id="business_locations0_addresses0_address_1"
                  label="Business Address 1"
                  :validation-error="
                    apiErrors['business_locations0_addresses0_address_1']
                  "
                  v-model="
                    submission.business.locations[0].addresses[0].address_1
                  "
                />
              </div>
              <div class="col-12 col-md-4">
                <InputField
                  id="business_locations0_addresses0_address_2"
                  label="Business Address 2"
                  :validation-error="
                    apiErrors['business_locations0_addresses0_address_2']
                  "
                  v-model="
                    submission.business.locations[0].addresses[0].address_2
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="business_locations0_addresses0_city"
                  label="Business City"
                  :validation-error="
                    apiErrors['business_locations0_addresses0_city']
                  "
                  v-model="submission.business.locations[0].addresses[0].city"
                />
              </div>
              <div class="col-12 col-md-4">
                <SelectField
                  id="business_locations0_addresses0_state"
                  label="Business State"
                  :validation-error="
                    apiErrors['business_locations0_addresses0_state']
                  "
                  :options="select.states"
                  v-model="submission.business.locations[0].addresses[0].state"
                />
              </div>
              <div class="col-12 col-md-2">
                <InputField
                  id="business_locations0_addresses0_postal_code"
                  label="Business ZIP"
                  type="number"
                  :validation-error="
                    apiErrors['business_locations0_addresses0_postal_code']
                  "
                  v-model="
                    submission.business.locations[0].addresses[0].postal_code
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="business_locations0_phonenumbers0_number"
                  label="Business Phone"
                  type="phone"
                  :validation-error="
                    apiErrors['business_locations0_phonenumbers0_number']
                  "
                  v-model="
                    submission.business.locations[0].phonenumbers[0].number
                  "
                />
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="business_email"
                  label="Business Email"
                  type="email"
                  :validation-error="apiErrors['business_email']"
                  v-model="submission.business.email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="business_tin"
                  label="Business Tax ID"
                  placeholder="88-8888888"
                  :validation-error="apiErrors['business_tin']"
                  v-model="submission.business.tin"
                />
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group calendar-inline">
                  <label for="business_start_date">Business Start Date</label
                  ><br />
                  <datepicker
                    v-model="submission.business.start_date"
                    input-class="form-control w-96"
                    :class="['typeable-date']"
                    format="MM/dd/yyyy"
                    :typeable="true"
                    :calendarButton="false"
                    placeholder="MM/DD/YYYY"
                    minimum-view="month"
                    @selected="
                      (selectedDate) => {
                        dateSelected(selectedDate, 'business_start_date');
                      }
                    "
                    id="business_start_date"
                  >
                    <template v-slot:belowDate>
                      <slot name="beforeCalendarHeader"><span></span></slot>
                    </template>
                  </datepicker>
                  <ErrorMessage
                    name="business_start_date"
                    class="error-feedback"
                  />
                  <span
                    v-if="apiErrors['business_start_date']"
                    class="text-danger"
                    >{{ apiErrors['business_start_date'] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="business_gross_annual_revenue"
                  label="Gross Annual Revenue"
                  type="number"
                  :validation-error="apiErrors['business_gross_annual_revenue']"
                  v-model="submission.business.gross_annual_revenue"
                />
              </div>
              <div class="col-12 col-md-6"></div>
            </div>
          </div>
          <!-- Owner Information -->
          <div>
            <h4 class="mt-4">Owner Information</h4>
            <hr />
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_first_name"
                  label="Owner First Name"
                  :validation-error="apiErrors['owners0_first_name']"
                  v-model="submission.owners[0].first_name"
                />
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_last_name"
                  label="Owner Last Name"
                  :validation-error="apiErrors['owners0_last_name']"
                  v-model="submission.owners[0].last_name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_emails0_email"
                  label="Owner Email"
                  :validation-error="apiErrors['owners0_emails0_email']"
                  type="email"
                  v-model="submission.owners[0].email"
                />
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_phonenumbers0_number"
                  label="Owner Phone"
                  type="number"
                  :validation-error="apiErrors['owners0_phonenumbers0_number']"
                  v-model="submission.owners[0].phonenumbers[0].number"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group calendar-inline">
                  <label for="owners0_birth_date">Owner Birth Date</label><br />
                  <datepicker
                    v-model="submission.owners[0].birth_date"
                    input-class="form-control w-96"
                    :class="['typeable-date']"
                    format="MM/dd/yyyy"
                    :typeable="true"
                    :calendarButton="false"
                    placeholder="MM/DD/YYYY"
                    :use-utc="true"
                    @selected="
                      (selectedDate) => {
                        dateSelected(selectedDate, 'owners0_birth_date');
                      }
                    "
                    id="owners0_birth_date"
                  >
                    <template v-slot:belowDate>
                      <slot name="beforeCalendarHeader"><span></span></slot>
                    </template>
                  </datepicker>
                  <ErrorMessage
                    name="owners0_birth_date"
                    class="error-feedback"
                  />
                  <span
                    v-if="apiErrors['owners0_birth_date']"
                    class="text-danger"
                    >{{ apiErrors['owners0_birth_date'] }}</span
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_ssn"
                  label="SSN#"
                  :validation-error="apiErrors['owners0_ssn']"
                  v-model="submission.owners[0].ssn"
                />
              </div>
            </div>
            <div class="row" v-if="showMapSearch">
              <div class="col-12">
                <div class="form-group">
                  <label for="owner_address">Owner Address Search</label>
                  <VueGoogleAutocomplete
                    id="owner_address"
                    country="us"
                    classname="form-control company-color"
                    placeholder="Start typing"
                    @placechanged="
                      setAddress(submission.owners[0].addresses[0], $event)
                    "
                  >
                  </VueGoogleAutocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8">
                <InputField
                  id="owners0_addresses0_address_1"
                  label="Owner Address 1"
                  :validation-error="apiErrors['owners0_addresses0_address_1']"
                  v-model="submission.owners[0].addresses[0].address_1"
                />
              </div>
              <div class="col-12 col-md-4">
                <InputField
                  id="owners0_addresses0_address_2"
                  label="Owner Address 2"
                  :validation-error="apiErrors['owners0_addresses0_address_2']"
                  v-model="submission.owners[0].addresses[0].address_2"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_addresses0_city"
                  label="Owner City"
                  :validation-error="apiErrors['owners0_addresses0_city']"
                  v-model="submission.owners[0].addresses[0].city"
                />
              </div>
              <div class="col-12 col-md-4">
                <SelectField
                  id="owners0_addresses0_state"
                  label="Owner State"
                  :validation-error="apiErrors['owners0_addresses0_state']"
                  :options="select.states"
                  v-model="submission.owners[0].addresses[0].state"
                />
              </div>
              <div class="col-12 col-md-2">
                <InputField
                  id="owners0_addresses0_postal_code"
                  label="Owner ZIP"
                  type="number"
                  :validation-error="
                    apiErrors['owners0_addresses0_postal_code']
                  "
                  v-model="submission.owners[0].addresses[0].postal_code"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_percentage_ownership"
                  label="Ownership %"
                  type="number"
                  :validation-error="apiErrors['owners0_percentage_ownership']"
                  v-model="submission.owners[0].percentage_ownership"
                />
              </div>
              <div class="col-12 col-md-6">
                <InputField
                  id="owners0_fico"
                  label="Approximate Credit Score"
                  type="number"
                  :validation-error="apiErrors['owners0_fico']"
                  v-model="submission.owners[0].fico"
                />
              </div>
            </div>
          </div>
          <!-- File Uploader -->
          <h4 class="mt-4 d-none d-md-block">
            Bank Statements and other closing documents can be uploaded below
          </h4>
          <h4 class="mt-4 d-block d-md-none">Documents Upload</h4>
          <div class="row mt-4">
            <div class="col uploader">
              <div
                class="drop-here drop-here d-flex align-items-center justify-content-center"
                @dragover.prevent
                @drop="onDrop"
                v-if="attachments.length === 0"
              >
                <div class="drop-text text-center">
                  Drop files here to upload<br />
                  or<br />
                  <label
                    class="btn kap-btn-outline-primary display-inline mt-3"
                  >
                    Select Files
                    <input
                      type="file"
                      multiple
                      name="image"
                      @change="onFileSelected"
                      accept="image/*;capture=camera"
                      ref="fileUploads"
                    />
                  </label>
                </div>
              </div>
              <div v-if="attachments.length > 0">
                <div
                  v-for="(file, index) in attachments"
                  class="pt-2 border-bottom"
                  v-bind:key="'attachment-' + index"
                  v-bind:id="'attachment-' + index"
                >
                  <div class="row pb-1">
                    <div class="col-10 text-break">
                      {{ file.name }}
                      <span v-if="file.size">({{ file.size }})</span>
                      <span v-if="file.uuid"
                        >&nbsp;[{{ select.document_types[file.type] }}]</span
                      >
                      <span v-if="!file.uuid"
                        >&nbsp;
                        <select v-model="file.type" class="kap-inline-select">
                          <option
                            v-for="(item, index) in select.document_types"
                            v-bind:key="index"
                            :value="index"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </span>

                      <font-awesome-icon
                        :icon="['fas', 'check']"
                        class="text-success ml-1"
                        v-if="file.uuid"
                      />
                    </div>
                    <div class="col-2 text-right">
                      <button
                        class="btn kap-btn-outline-primary btn-remove"
                        @click="removeFile(index, $event)"
                        :id="'remove-attachment-' + index"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          class="text-danger"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="progress"
                        v-if="file.progress > 0 && file.progress < 100"
                      >
                        <div
                          :class="{
                            'progress-bar': true,
                            'bg-danger': file.error,
                            'progress-bar-animated': false,
                            'progress-bar-striped': false,
                          }"
                          role="progressbar"
                          :style="{ width: file.progress + '%' }"
                        >
                          {{ file.progress }} %
                        </div>
                      </div>
                      <small
                        class="form-text text-danger py-2"
                        v-show="file.error"
                      >
                        {{ file.error }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <label
                    class="btn kap-btn-outline-primary display-inline mt-3"
                  >
                    Add Files
                    <input
                      type="file"
                      multiple
                      name="image"
                      @change="onFileSelected"
                      accept="image/*;capture=camera"
                      ref="fileUploads"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="
              hasErrors || hasApiValidationErrors || Object.keys(errors).length
            "
            class="error-feedback"
          >
            The changes could not be saved. Please fix errors.
          </div>
          <div
            v-if="
              hasErrors &&
              !hasApiValidationErrors &&
              Object.keys(errors).length === 0
            "
            class="error-feedback"
          >
            The changes could not been saved. Please contact your Partner
            Manager.
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button
              type="submit"
              class="btn big-button navigation-button save"
              v-bind:disabled="
                hasApiValidationErrors || Object.keys(errors).length
              "
            >
              <div
                class="spinner-border spinner-border-sm text-info"
                role="status"
                v-if="isSaving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              Save
            </button>
            <a
              href="#"
              class="cancel-link company-btn cancel"
              v-bind:onclick="handleOnCancelClick"
              id="cancel-button"
              >Cancel</a
            >
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';
import { ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';
import { Dictionary } from '@/constants/dictionary';
import utils from '../../utils/';
import apiService from '../../plugins/api';
import { each, filter } from 'underscore';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import config from '../../config';
import DashboardNavigation from '../DashboardNavigation';
import InputField from '@/components/Form/Fields/InputField';
import SelectField from '@/components/Form/Fields/SelectField';

export default {
  name: 'EditSubmission',
  props: ['handleFiltersChanged'],
  components: {
    SelectField,
    InputField,
    Form,
    ErrorMessage,
    Datepicker,
    VueGoogleAutocomplete,
    DashboardNavigation,
  },
  data: function () {
    const schema = yup.object().shape(this.getValidationSchema());

    return {
      schema,
      isLoading: false,
      isSaving: false,
      hasErrors: false,
      initialSubmission: null,
      submission: {
        urgency: '',
        requested_funding: '',
        use_of_funds: null,
        extra: {
          locked: false,
          hear_about_us: '',
          accept_terms: false,
          allowed_to_call: false,
        },
        business: {
          uuid: '',
          name: '',
          dba: '',
          industry: '',
          entity_type: null,
          start_date: '',
          tin: '',
          tin_provided: false,
          gross_annual_revenue: '',
          locations: [
            {
              uuid: '',
              addresses: [
                {
                  uuid: '',
                  address_1: '',
                  country: '',
                  city: '',
                  state: '',
                  postal_code: '',
                },
              ],
              phonenumbers: [
                {
                  uuid: '',
                  number: '',
                  type: 'work',
                },
              ],
            },
          ],

          email: '',
        },
        owners: [
          {
            uuid: '',
            first_name: '',
            last_name: '',
            percentage_ownership: '',
            birth_date: '',
            ssn: '',
            ssn_provided: false,
            signature: '',
            fico: '',
            addresses: [
              {
                uuid: '',
                address_1: '',
                country: '',
                city: '',
                state: '',
                postal_code: '',
              },
            ],
            phonenumbers: [
              {
                uuid: '',
                number: '',
                type: 'cell',
              },
            ],
            email: '',
          },
        ],
      },
      select: Dictionary,
      apiErrors: {},
      isNew: false,

      // Uploader
      uuid: '',
      attachments: [],
      numberOfUploadedFiles: 0,
      uploadStatus: false,
      showMapSearch: false,
    };
  },

  computed: {
    hasApiValidationErrors() {
      return Object.keys(this.apiErrors).length;
    },
  },

  mounted() {
    this.uuid = this.$route.params.submission_id;
    if (this.uuid) {
      this.getData();
    }

    if (config.Google.apiKey && !window.google) {
      const mapApiScript = document.createElement('script');
      mapApiScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${config.Google.apiKey}&libraries=places`
      );
      mapApiScript.setAttribute('id', 'map-api-script');
      mapApiScript.onload = () => {
        this.showMapSearch = true;
        window.gm_authFailure = () => {
          this.showMapSearch = false;
        };
      };
      this.$el.appendChild(mapApiScript);
    } else if (window.google) {
      this.showMapSearch = true;
    }
  },
  methods: {
    dateSelected(selectedDate, fieldName) {
      if (fieldName === 'owners0_birth_date') {
        this.submission.owners[0].birth_date = selectedDate;
      }

      if (fieldName === 'business_start_date') {
        this.submission.business.start_date = selectedDate;
      }
    },
    onFormChange: function (field) {
      if (this.apiErrors[field.target.id]) {
        delete this.apiErrors[field.target.id];
        if (this.hasErrors && Object.keys(this.apiErrors).length === 0) {
          this.hasErrors = false;
        }
      }
    },
    setAddress: function (currentAddress, selectedAddress) {
      currentAddress.address_1 = `${selectedAddress.street_number || ''} ${
        selectedAddress.route || ''
      }`;
      currentAddress.address_2 = '';
      currentAddress.state = selectedAddress.administrative_area_level_1 || '';
      currentAddress.postal_code = selectedAddress.postal_code || '';
      currentAddress.city = selectedAddress.locality || '';
    },

    getData: function () {
      this.isLoading = true;
      apiService()
        .getSubmission(this.uuid)
        .then((response) => {
          if (!this.submissionIsEditable(response.data)) {
            this.$router.push(`/submission/${this.uuid}`);
            return false;
          }
          this.setDataFromResponse(response);
          if ((response.data.documents || []).length > 0) {
            this.newFilesAttached(response.data.documents);
          }
          this.isLoading = false;
          document.title = `Edit "${
            this.submission.business ? this.submission.business.name : ''
          }" application`;
          return true;
        })
        .catch(() => {
          this.isLoading = false;
          this.$router.push({ path: '/404' });
        });
    },

    setDataFromResponse(response) {
      const data = JSON.parse(JSON.stringify(response.data));

      this.uuid = this.getFieldValue('uuid', data);
      this.submission.extra = this.getFieldValue('extra', data);
      this.submission.requested_funding = this.getFieldValue(
        'requested_funding',
        data
      );
      this.submission.urgency = this.getFieldValue('urgency', data);
      this.submission.use_of_funds = this.getFieldValue('use_of_funds', data);
      this.submission.business.uuid = this.getFieldValue('business.uuid', data);
      this.submission.business.name = this.getFieldValue('business.name', data);
      this.submission.business.dba = this.getFieldValue('business.dba', data);
      this.submission.business.gross_annual_revenue = this.getFieldValue(
        'business.gross_annual_revenue',
        data
      );
      this.submission.business.industry = this.getFieldValue(
        'business.industry',
        data
      );
      this.submission.business.entity_type = utils.mapOldSubmissionEntityType(
        this.getFieldValue('business.entity_type', data)
      );
      this.submission.business.locations[0].uuid = this.getFieldValue(
        'business.locations.0.uuid',
        data
      );
      this.submission.business.locations[0].addresses[0].uuid =
        this.getFieldValue('business.locations.0.addresses.0.uuid', data);
      this.submission.business.locations[0].addresses[0].address_1 =
        this.getFieldValue('business.locations.0.addresses.0.address_1', data);
      this.submission.business.locations[0].addresses[0].address_2 =
        this.getFieldValue('business.locations.0.addresses.0.address_2', data);
      this.submission.business.locations[0].addresses[0].city =
        this.getFieldValue('business.locations.0.addresses.0.city', data);
      this.submission.business.locations[0].addresses[0].state =
        this.getFieldValue('business.locations.0.addresses.0.state', data);
      this.submission.business.locations[0].addresses[0].postal_code =
        this.getFieldValue(
          'business.locations.0.addresses.0.postal_code',
          data
        );
      this.submission.business.locations[0].phonenumbers[0].uuid =
        this.getFieldValue('business.locations.0.phonenumbers.0.uuid', data);
      this.submission.business.locations[0].phonenumbers[0].number =
        this.getFieldValue('business.locations.0.phonenumbers.0.number', data);
      this.submission.business.start_date = this.formatDateForCalendar(
        this.getFieldValue('business.start_date', data),
        true
      );

      this.submission.business.email = '';
      if (this.fieldIsNotEmpty('business.emails.0.email', data)) {
        this.submission.business.email = this.getFieldValue(
          'business.emails.0.email',
          data
        );
        this.submission.business.emails = this.getFieldValue(
          'business.emails',
          data
        );
      }
      if (this.getFieldValue('business.tin_provided', data)) {
        this.submission.business.tin = '**-*******';
      }

      this.submission.owners[0].uuid = this.getFieldValue(
        'owners.0.uuid',
        data
      );
      this.submission.owners[0].first_name = this.getFieldValue(
        'owners.0.first_name',
        data
      );
      this.submission.owners[0].last_name = this.getFieldValue(
        'owners.0.last_name',
        data
      );
      this.submission.owners[0].birth_date = this.formatDateForCalendar(
        this.getFieldValue('owners.0.birth_date', data)
      );
      this.submission.owners[0].fico = this.getFieldValue(
        'owners.0.fico',
        data
      );
      this.submission.owners[0].percentage_ownership = this.getFieldValue(
        'owners.0.percentage_ownership',
        data
      );
      this.submission.owners[0].addresses[0].uuid = this.getFieldValue(
        'owners.0.addresses.0.uuid',
        data
      );
      this.submission.owners[0].addresses[0].address_1 = this.getFieldValue(
        'owners.0.addresses.0.address_1',
        data
      );
      this.submission.owners[0].addresses[0].address_2 = this.getFieldValue(
        'owners.0.addresses.0.address_2',
        data
      );
      this.submission.owners[0].addresses[0].city = this.getFieldValue(
        'owners.0.addresses.0.city',
        data
      );
      this.submission.owners[0].addresses[0].state = this.getFieldValue(
        'owners.0.addresses.0.state',
        data
      );
      this.submission.owners[0].addresses[0].postal_code = this.getFieldValue(
        'owners.0.addresses.0.postal_code',
        data
      );
      if (this.getFieldValue('owners.0.ssn_provided', data)) {
        this.submission.owners[0].ssn = '***-**-****';
      }
      //
      this.submission.owners[0].email = '';
      if (this.fieldIsNotEmpty('owners.0.emails.0.email', data)) {
        this.submission.owners[0].email = this.getFieldValue(
          'owners.0.emails.0.email',
          data
        );
        this.submission.owners[0].emails = this.getFieldValue(
          'owners.0.emails',
          data
        );
      }
      this.submission.owners[0].phonenumbers[0].uuid = this.getFieldValue(
        'owners.0.phonenumbers.0.uuid',
        data
      );
      this.submission.owners[0].phonenumbers[0].number = this.getFieldValue(
        'owners.0.phonenumbers.0.number',
        data
      );

      this.initialSubmission = this.submission;
    },

    formatDateForCalendar(date) {
      return !date ? null : new Date(date + 'T08:00:00.000Z');
    },

    getDataToSubmit() {
      const data = utils.sanitize(this.submission);

      this.prepareBusinessInformationToSubmit(data);
      this.prepareOwnerInformationToSubmit(data);

      return data;
    },

    prepareBusinessInformationToSubmit(data) {
      if (data.business.start_date) {
        data.business.start_date = data.business.start_date.split('T')[0];
      }
      if (typeof data.requested_funding === 'undefined') {
        data.requested_funding = null;
      }
      if (data.business.tin) {
        data.business.tin = data.business.tin.replace(/\D/g, '');
        if (data.business.tin.length === 0) {
          delete data.business.tin;
        }
      }

      each(this.initialSubmission.business, (item, idx) => {
        if (typeof data.business[idx] === 'undefined') {
          data.business[idx] = null;
        }
      });

      this.prepareBusinessLocationToSubmit(data);
    },

    prepareBusinessLocationToSubmit(data) {
      if (data.business?.locations && data.business.locations.length) {
        this.prepareAddresses(
          data.business.locations[0],
          this.initialSubmission.business.locations[0]
        );
        this.preparePhones(
          data.business.locations[0],
          this.initialSubmission.business.locations[0]
        );
      }
    },

    preparePhones(entity, initialEntity) {
      if (entity.phonenumbers && entity.phonenumbers.length) {
        each(initialEntity.phonenumbers[0], (item, idx) => {
          if (typeof entity.phonenumbers[0][idx] === 'undefined') {
            entity.phonenumbers[0][idx] = null;
          }
        });
      }
    },

    prepareAddresses(entity, initialEntity) {
      if (entity.addresses && entity.addresses.length) {
        each(initialEntity.addresses[0], (item, idx) => {
          if (typeof entity.addresses[0][idx] === 'undefined') {
            entity.addresses[0][idx] = null;
          }
        });
      }
    },

    prepareOwnerInformationToSubmit(data) {
      each(data.owners, (owner, idx) => {
        if (owner.ssn) {
          owner.ssn = owner.ssn.replace(/\D/g, '');
          if (owner.ssn.length === 0) {
            delete data.owners[idx].ssn;
          }
        }
        if (owner.birth_date) {
          owner.birth_date = owner.birth_date.split('T')[0];
        }
        this.prepareAddresses(
          data.owners[idx],
          this.initialSubmission.owners[idx]
        );
        this.preparePhones(
          data.owners[idx],
          this.initialSubmission.owners[idx]
        );
      });
    },

    updateSubmission() {
      this.isSaving = true;
      this.apiErrors = {};
      apiService()
        .updatePartialSubmission(this.uuid, this.getDataToSubmit())
        .then(async (response) => {
          this.isSaving = false;
          this.setDataFromResponse(response);
          await this.uploadFiles();
          this.$router.push('/submission/' + this.uuid);
        })
        .catch((e) => {
          if (e.data) {
            this.apiErrors = this.displayValidationErrors(
              utils.flattenApiValidationErrors(e.data)
            );
          }
          this.hasErrors = true;
          this.isSaving = false;
        });
    },

    displayValidationErrors(errorsFromApi) {
      return this.remapValidationErrorValues(errorsFromApi);
    },

    remapValidationErrorValues(errorsFromApi) {
      this.mapErrorMessage(
        errorsFromApi,
        'owners0_addresses0_address_1',
        'Owner Street is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'owners0_addresses0_city',
        'Owner City is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'owners0_addresses0_state',
        'Owner State is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'owners0_addresses0_postal_code',
        'Owner Zip is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'business_locations0_addresses0_address_1',
        'Business Street is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'business_locations0_addresses0_city',
        'Business City is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'business_locations0_addresses0_state',
        'Business State is required!'
      );
      this.mapErrorMessage(
        errorsFromApi,
        'business_locations0_addresses0_postal_code',
        'Business Zip is required!'
      );
      return errorsFromApi;
    },

    mapErrorMessage(errorsFromApi, key, newErrorMessage) {
      if (key in errorsFromApi) {
        errorsFromApi[key] = newErrorMessage;
      }
    },

    newFilesAttached(selectedFiles) {
      for (const selectedFile of selectedFiles) {
        const attachment = {
          file: selectedFile,
          name: selectedFile.name,
          size: this.readableFileSize(selectedFile.size),
          progress: 0,
          error: false,
          type: 'bs',
        };

        if (selectedFile['uuid']) {
          attachment.progress = 100;
          attachment.uuid = selectedFile['uuid'];
          attachment.type = selectedFile['type'];
        }

        this.attachments.push(attachment);
      }
    },

    readableFileSize(size) {
      if (!size) {
        return null;
      }
      const i = Math.floor(Math.log(size) / Math.log(1024));
      const fileSize = (size / Math.pow(1024, i)).toFixed(2) * 1;
      const sizeMeasurement = ['B', 'kB', 'MB', 'GB', 'TB'][i];
      return `${fileSize} ${sizeMeasurement}`;
    },

    async uploadFiles() {
      const numberOfAttachedFiles = this.attachments.length;
      if (numberOfAttachedFiles > 0) {
        this.isSaving = true;
      }

      for (let i = 0; i < this.attachments.length; i++) {
        if (this.attachments[i].file['uuid']) {
          this.numberOfUploadedFiles++;
          continue;
        }

        const form = new FormData();
        form.append('document', this.attachments[i].file);
        form.append('type', this.attachments[i].type || 'bs');

        const uploadProgress = function (progressEvent) {
          this.attachments[i].progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
          .bind(this)
          .bind(i);

        try {
          const response = await apiService().uploadDocument(
            this.uuid,
            form,
            uploadProgress
          );
          this.numberOfUploadedFiles++;
          this.attachments[i].uuid = response.data.uuid;
        } catch (err) {
          console.error(err);
        } finally {
          if (this.numberOfUploadedFiles >= numberOfAttachedFiles) {
            this.isSaving = false;
          }
        }
      }
    },

    removeFile(i, event) {
      event.preventDefault();
      this.removeFileFromInputField(i);
      if (this.attachments[i].file.uuid) {
        apiService().deleteDocument(this.uuid, this.attachments[i].file.uuid);
      }
      this.attachments.splice(i, 1);
    },

    removeFileFromInputField(i) {
      const files =
        filter(this.$refs['fileUploads'].files, (file) => {
          return file !== this.attachments[i].file;
        }) || [];
      const dt = new DataTransfer();
      for (const file in files) {
        dt.items.add(file, file.type);
      }
      this.$refs['fileUploads'].files = dt.files;
    },

    changeProgress(progress, i) {
      this.attachments[i].progress = progress;
    },

    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.newFilesAttached(e.dataTransfer.files);
    },

    onFileSelected(e) {
      this.newFilesAttached(e.target.files);
    },

    getFieldValue: function (paramName, source = this.submission) {
      const c = paramName.split('.');
      let d = source;

      c.forEach(function (k) {
        if (typeof d[k] !== 'undefined' && d[k] !== null) {
          d = d[k];
        } else {
          d = '';
        }
      });
      return d;
    },

    fieldIsNotEmpty: function (fieldName, data = this.submission) {
      const fieldValue = this.getFieldValue(fieldName, data);
      return (
        fieldValue !== null &&
        fieldValue !== '0' &&
        fieldValue !== '$0' &&
        fieldValue.length !== 0
      );
    },

    submissionIsEditable: function (submission) {
      return (
        utils.isSubmissionEditable(submission) &&
        !utils.isSubmissionFromSalesforce(submission)
      );
    },

    handleOnCancelClick: function () {
      if (this.isNew) {
        this.$router.go(-2);
      } else {
        this.$router.push('/submission/' + this.uuid);
      }
    },

    getValidationSchema() {
      const stateValidation = function (value) {
        if (value) {
          return /^\d{5}$/.test(value);
        }
        return true;
      };

      return {
        owners0_emails0_email: yup
          .string()
          .required('Owner Email is required!')
          .email('Owner Email is invalid'),
        owners0_phonenumbers0_number: yup
          .number()
          .required('Owner Phone is required!')
          .transform((v, originalValue) =>
            originalValue.length === 0 ? null : v
          )
          .typeError('Owner Phone must be a valid phone number')
          .min(1000000000, 'Owner Phone must contain 10 digits')
          .max(9999999999, 'Owner Phone must contain 10 digits'),
        business_locations0_phonenumbers0_number: yup
          .number()
          .transform((v, originalValue) =>
            originalValue.length === 0 ? null : v
          )
          .nullable()
          .typeError('Business Phone must be a valid phone number')
          .min(1000000000, 'Business Phone must contain 10 digits')
          .max(9999999999, 'Business Phone must contain 10 digits'),
        business_name: yup.string().required('Business Name is required!'),
        owners0_first_name: yup
          .string()
          .required('Owner First Name is required!'),
        owners0_last_name: yup
          .string()
          .required('Owner Last Name is required!'),
        requested_funding: yup
          .string()
          .test(
            'requested_funding',
            'The provided value is invalid',
            function (value) {
              if (value && !/^-?\d+$/.test(value)) {
                return false;
              }
              const requestedFunding = Number(value);
              if (requestedFunding) {
                return yup.number().min(1).isValidSync(requestedFunding);
              }
              return true;
            }
          ),
        business_email: yup
          .string()
          .notRequired()
          .test(
            'business_email',
            'Business Email should be a valid email',
            function (value) {
              if (value) {
                return yup.string().email().isValidSync(value);
              }
              return true;
            }
          ),
        business_tin: yup
          .string()
          .notRequired()
          .test(
            'business_tin',
            'Tax Id should be XX-XXXXXXX',
            function (value) {
              if (value) {
                return value === '**-*******' || /^\d{2}-?\d{7}$/.test(value);
              }
              return true;
            }
          ),
        owners0_ssn: yup
          .string()
          .notRequired()
          .test(
            'owners0_ssn',
            'SSN should be a valid XXX-XX-XXXX',
            function (value) {
              if (value) {
                return (
                  value === '***-**-****' || /^\d{3}-\d{2}-\d{4}$/.test(value)
                );
              }
              return true;
            }
          ),
        owners0_percentage_ownership: yup
          .string()
          .notRequired()
          .test(
            'owners0_percentage_ownership',
            'Ownership % cannot be negative or bigger than 100%',
            function (value) {
              if (value) {
                return value > 0 && value <= 100;
              }
              return true;
            }
          ),
        owners0_addresses0_postal_code: yup
          .string()
          .notRequired()
          .test(
            'owners0_addresses0_postal_code',
            'Owner ZIP should be a valid zip code XXXXX',
            stateValidation
          ),
        business_locations0_addresses0_postal_code: yup
          .string()
          .notRequired()
          .test(
            'business_locations0_addresses0_postal_code',
            'Business ZIP should be a valid zip XXXXX',
            stateValidation
          ),
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/submission/new') {
        vm.isNew = true;
      } else {
        vm.isNew = false;
      }
    });
  },
};
</script>

<style></style>
